@value (
  --color-danger,
  --color-text,
  --color-white,
  --font-size-base,
  --font-size-smallest,
  --font-weight-medium,
  --font-weight-regular,
  --size-5x
) from "~variables.css";
@value (
  --border-color-input,
  --input-value-offset
) from "~components/FormControls/style.css";

.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: block;
  margin: 0;
  padding: 0 --input-value-offset;
  height: --size-5x;
  width: 100%;
  border: 1px solid --border-color-input;
  background: --color-white;
  border-radius: 4px;
  font-size: --font-size-base;
  font-weight: --font-weight-medium;
}

.input--invalid {
  border-color: --color-danger;
  border-width: 2px;
  outline-color: --color-danger;
}

.input-label {
  position: absolute;
  font-weight: --font-weight-regular;
  font-size: --font-size-smallest;
  line-height: --font-size-smallest;
  color: --color-text;
  background: --color-white;
  padding: 0 5px;
  transform: translate(7px, -6px);
}

.input-wrapper {
  position: relative;
  width: 100%;
  padding-top: 6px;
}

.heatmap {
  overflow-x: scroll;
}
