.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
