@value (
  --color-foreground,
  --box-shadow-surface-2,
  --space-1x,
  --space-2x
) from "~variables.css";

.root {
  height: 100%;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  background-color: --color-foreground;
  box-shadow: --box-shadow-surface-2;
  padding: --space-1x --space-2x;
}

.tooltip-value {
  margin-bottom: --space-1x;
  white-space: nowrap;
}
