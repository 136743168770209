@value --font-weight-bold from "~variables.css";

.root {
  height: 100%;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-slice--hovered {
  font-weight: --font-weight-bold;
}
