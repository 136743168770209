/* stylelint-disable font-family-no-missing-generic-family-keyword */
@value (
  --marketing-color-primary,
  --marketing-font-family-headings
) from "~variables.css";
@value (
  --breakpoint-large,
  --breakpoint-medium
) from "~breakpoints.css";

/**
 * GENERAL LAYOUT
 */

.root {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

/**
 * HERO UNIT
 */

.hero {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;
  padding-top: 5em;
}

.hero-headings {
  max-width: 420px;
}

.hero-buttons {
  display: flex;
  margin-top: 2em;
}

.hero-button {
  font-weight: 700;
  font-size: 1em;
  color: #fff !important;
  background-color: --marketing-color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em 2em;
  text-transform: uppercase;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.hero-button:not(:last-child) {
  margin-right: 1em;
  background-color: #33435c;
}

.hero-image {
  position: relative;
  top: -2em;
  z-index: -1;
  width: 130%;
  margin: auto 0;
}

.hero-image img {
  width: 100%;
}

.hero-image::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  /**
   * Using `rgba()` here instead of `transparent` because of Safari bug:
   * https://css-tricks.com/thing-know-gradients-transparent-black/
   */
  background: linear-gradient(#fff, rgba(255, 255, 255, 0) 50%);
}

.hero h1 {
  font-size: 3em;
  font-weight: 700;
  font-family: --marketing-font-family-headings;
}

.hero h2 {
  font-size: 1.5em;
  font-weight: 500;
}

@media (--breakpoint-medium) {
  .hero {
    align-items: flex-start;
    justify-content: center;
    padding: 5em 0;
    height: 900px;
  }

  .hero-headings {
    text-align: left;
  }

  .hero h1 {
    font-size: 4em;
    line-height: 1.4em;
  }

  .hero h2 {
    font-size: 1.875em;
    line-height: 1.2em;
  }

  .hero-image {
    position: absolute;
    left: 420px;
    top: 115px;
    width: 766px;
  }

  .hero-image::before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hero-image {
    left: unset;
    right: -140px;
    top: 115px;
    width: 766px;
  }
}

/**
 * INDUSTRIES SECTION
 */

.industries {
  display: flex;
  flex-direction: column;
}

.industry {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 3px 3px 10px #ddd;
  margin: 1.5rem;
  padding: 2.5rem 2.5rem 3.5rem;
}

.industry-image {
  height: 260px;
  margin: -2.5rem -2.5rem 2.5rem -2.5rem;
  width: calc(100% + 5rem);
  object-fit: cover;
}

.industry-header {
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 2.5rem;
  font-family: --marketing-font-family-headings;
}

.industry-description {
  display: block;
  width: 100%;
  font-size: 1em;
}

@media (--breakpoint-large) {
  .industries {
    flex-direction: row;
    justify-content: stretch;
    margin: 0 -1.5rem;
  }

  .industry {
    flex: 0 1 33%;
  }
}

/**
 * COLLABORATIONS SECTION
 */

.collaborations {
  display: flex;
  flex-direction: column;
}

.collaboration {
  background: #fff;
  box-shadow: 3px 3px 10px #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1.5rem;
  padding: 5rem 2.5rem;
}

.collaboration-icon {
  width: 9rem;
  height: 9rem;
  object-fit: contain;
  margin: 3rem auto;
}

.collaboration-icon-explore {
  composes: collaboration-icon;
  width: 12rem;
}

.collaboration-header {
  font-weight: 500;
  font-size: 3rem;
  margin-bottom: 2.5rem;
  font-family: --marketing-font-family-headings;
}

.collaboration-description {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.5em;
}

@media (--breakpoint-large) {
  .collaborations {
    flex-direction: row;
    margin: 0 -1.5rem;
  }

  .collaboration {
    flex: 1 0 25%;
    margin: 0 1.5rem;
  }

  .collaboration-description-tight {
    composes: collaboration-description;
    padding: 0 2.5rem;
  }
}

/**
 * FEATURES SECTION
 */

.features {
  display: flex;
  flex-direction: column;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 5.5rem;
}

.feature-icon {
  font-size: 7rem;
  line-height: 100%;
  width: 1em;
  height: 1em;
  margin-bottom: 2rem;
}

.feature-header {
  display: block;
  font-size: 2.5rem;
  margin-bottom: 0.5em;
  font-family: --marketing-font-family-headings;
  font-weight: 500;
}

.feature-description {
  display: block;
  width: 100%;
  font-size: 1em;
}

@media (--breakpoint-large) {
  .features {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .feature {
    flex: 1 0 33%;
  }
}

@media (hover: hover), (-moz-touch-enabled: 0) {
  .feature-description {
    /*
     * Hiding text with opacity: instead of visibility:
     * so it's still accessible to screen readers.
     */
    opacity: 0;
  }

  .feature:hover,
  .feature:focus {
    background: #f4f4f4;
    box-shadow: 3px 3px 10px #ddd;
  }

  .feature:hover .feature-description,
  .feature:focus .feature-description {
    opacity: 1;
  }
}
