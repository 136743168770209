@value (
  --color-gray-lightest,
  --color-primary,
  --color-white,
  --font-size-base,
  --font-size-smallest,
  --font-size-larger,
  --font-weight-medium,
  --font-weight-semibold,
  --size-3x,
  --size-5x,
  --space-1x,
  --space-3x
) from "~variables.css";
@value (
  --table-border
) from "~table/Table.css";
@value --color-controls-background: #f6fbff;
@value --color-controls-divider: #f3f3f3;

.root {
  border: --table-border;
  border-bottom: 0;
}

.root:first-child {
  border-radius: 4px 4px 0 0;
}

.header {
  display: flex;
  align-items: center;
  height: --size-5x;
  background-color: --color-gray-lightest;
  padding: 0 --space-3x;
}

.title {
  font-size: --font-size-base;
  font-weight: --font-weight-medium;
  margin-right: auto;
}

.content {
  display: flex;
}

.visualization {
  padding: --space-3x;
  flex-grow: 1;
}

.visualization--with-controls {
  composes: visualization;

  /* This overflow helps the visualization to shrink when controls panel becomes visible */
  overflow-x: hidden;
}

.controls {
  padding: --space-3x;
  flex: 0 0 380px;
  background: --color-controls-background;
  border-left: --table-border;
}

.controls-header {
  padding-bottom: --space-3x;
  margin-bottom: --space-3x;
  font-size: --font-size-larger;
  font-weight: --font-weight-semibold;
  line-height: --size-3x;
  border-bottom: 1px solid --color-controls-divider;
  display: flex;
  justify-content: space-between;
}

.controls-header-button {
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  width: --size-3x;
  height: --size-3x;
  background-color: transparent;
  background-image: url("~icons/icon-close-black.svg");
}

.content--collapsed {
  display: none;
}

.visualization-icon {
  display: flex;
  align-items: center;
  width: --size-5x;
  height: --size-5x;
  margin-left: calc(-1 * --space-1x); /* stylelint-disable-line function-calc-no-unspaced-operator */
  margin-right: --space-1x;
  padding: 5px;
}

.visualization-icon > img {
  width: 100%;
  background: --color-white;
  border-radius: 4px;
}

.button-icon {
  width: --size-3x;
  height: --size-3x;
  margin-right: --space-1x;
}

.button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: --font-size-smallest;
  font-weight: --font-weight-medium;
}

.button:hover,
.button:active {
  color: --color-primary;
}

.button--close,
.button--controls {
  composes: button;
}

.button--expand,
.button--collapse {
  composes: button;
  min-width: 80px;
}

.button--close .button-icon {
  background: url("~icons/icon-trash-black.svg");
}

.button--collapse .button-icon {
  background: url("~icons/icon-collapse-black.svg");
}

.button--expand .button-icon {
  background: url("~icons/icon-expand-black.svg");
}

.button--controls .button-icon {
  background: url("~icons/icon-settings-black.svg");
}

.button--close:hover .button-icon,
.button--close:active .button-icon {
  background: url("~icons/icon-trash-primary.svg");
}

.button--collapse:hover .button-icon,
.button--collapse:active .button-icon {
  background: url("~icons/icon-collapse-primary.svg");
}

.button--expand:hover .button-icon,
.button--expand:active .button-icon {
  background: url("~icons/icon-expand-primary.svg");
}

.button--controls:hover .button-icon,
.button--controls:active .button-icon {
  background: url("~icons/icon-settings-primary.svg");
}
