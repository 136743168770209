@value --space-1x from "~variables.css";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.root { /* placeholder class */ }

.root :global(.glyphicon) {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin-right: --space-1x;
}

.root :global(.glyphicon)::before {
  position: relative;
  top: 1px;
}
