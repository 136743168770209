@value (
  --color-primary,
  --color-text,
  --color-foreground,
  --box-shadow-surface-0,
  --icon-tint-filter
) from "~variables.css";
@value --tab-separator-color: #dedede;

.tab-navigation {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 5px 0 0 5px;
  margin: -5px 0 0 -5px;
}

.tab {
  height: 46px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0 17px;
  font-weight: 500;
  font-size: 12px;
  color: --color-text;
  white-space: nowrap;
}

.tab:hover,
.tab:active,
.tab:focus {
  color: --color-primary;
  text-decoration: none;
}

.active-tab {
  box-shadow: --box-shadow-surface-0;
  background: --color-foreground;
  color: --color-primary;
  border-radius: 4px 4px 0;
}

.active-tab::after {
  content: "";
  background: --color-foreground;
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.tab::before {
  content: "";
  background: --tab-separator-color;
  height: 14px;
  width: 1px;
  position: absolute;
  left: 0;
  top: calc(50% - 7px);
}

.active-tab::before,
.active-tab + .tab::before,
.tab:first-child::before {
  display: none;
}

.tab-content-wrapper {
  box-shadow: --box-shadow-surface-0;
  background: --color-foreground;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
}

.tab-content {
  margin: 26px;
  width: calc(100% - 52px);
}

.tab > img {
  margin: 0;
  margin-right: 10px;
}

.active-tab > img {
  filter: --icon-tint-filter;
}
