@value (
  --color-secondary-green,
  --color-white,
  --font-size-base,
  --font-weight-medium,
  --size-5x,
  --space-2x
) from "~variables.css";

.root {
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 --space-2x;
  font-size: --font-size-base;
  border-radius: 4px;
  user-select: none;
  height: --size-5x;
  line-height: --size-5x;
  background: --color-secondary-green;
  border: 0;
  color: --color-white;
  font-weight: --font-weight-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  margin-right: 6px;
  margin-left: -4px;
}
