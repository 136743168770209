@value (
  --box-shadow-surface-2,
  --color-foreground,
  --color-gray-lighter,
  --color-primary,
  --color-secondary-blue,
  --color-text,
  --font-size-smaller,
  --font-weight-regular,
  --space-1x,
  --space-2x,
  --space-4x
) from "~variables.css";

.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-root {
  max-width: 1000px;
  margin: auto;
  position: relative;
}

.tooltip {
  background-color: --color-foreground;
  box-shadow: --box-shadow-surface-2;
  padding: --space-1x --space-2x;
}

.tooltip-value {
  margin-bottom: --space-1x;
  white-space: nowrap;
}

.threshold {
  display: flex;
}

.threshold-label {
  font-weight: --font-weight-regular;
  font-size: --font-size-smaller;
  line-height: --font-size-smaller;
  color: --color-text;
  margin-bottom: --space-1x;
}

.threshold-value {
  margin-left: --space-4x;
  width: 80px;
}

.threshold-slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  overflow: hidden;
}

.threshold-slider::-webkit-slider-runnable-track {
  appearance: none;
  background: --color-gray-lighter;
  height: 2px;
}

.threshold-slider::-moz-range-track {
  appearance: none;
  background: --color-gray-lighter;
  height: 2px;
}

.threshold-slider::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0;
  background: --color-primary;
  margin-top: -5px;

  /**
   * Trick with box-shadow and clip-path to simulate the lower (filled)
   * portion of the range input.
   * https://codepen.io/noahblon/pen/OyajvN
   **/
  box-shadow: -500px 0 0 488px --color-secondary-blue;
  clip-path: polygon(-500px 5px, 0 5px, 0 0, 120% 0, 100% 120%, 0 120%, 0 7px, -500px 7px);
}

.threshold-slider::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0;
  background: --color-primary;
  margin-top: -5px;
  box-shadow: -500px 0 0 488px --color-secondary-blue;
  clip-path: polygon(-500px 5px, 0 5px, 0 0, 120% 0, 100% 120%, 0 120%, 0 7px, -500px 7px);
}

input[type=range].threshold-slider {
  width: 100%; /* Overriding default range style from bootstrap */
}
