.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-root {
  max-width: 700px;
  min-height: 400px;
  height: calc(100vh - 100px);
  margin: auto;
  position: relative;
}

.plot-details {
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: right;
}

.plot-root :global(.recharts-wrapper) {
  margin: auto;
}
