@value (
  --box-shadow-surface-2,
  --color-foreground,
  --color-primary,
  --font-size-smallest,
  --font-weight-medium,
  --space-1x,
  --space-2x
) from "~variables.css";

.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.diagram-root {
  display: flex;
  justify-content: center;
}

.diagram-tooltip {
  position: fixed;
  opacity: 0;
  background-color: --color-foreground;
  box-shadow: --box-shadow-surface-2;
  padding: --space-1x --space-2x;
  z-index: 1;
}

.items-toggle-button {
  display: block;
  padding: 0;
  margin-top: --space-1x;
  border: 0;
  background: none;
  color: --color-primary;
  font-size: --font-size-smallest;
  font-weight: --font-weight-medium;
}
