.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-root {
  max-width: 700px;
  max-height: 700px;
  min-height: 400px;
  height: calc(100vh - 100px);
  margin: auto;
}

.plot-root :global(.x-axis .tick),
.plot-root :global(.y-axis .tick) {
  /* Overriding D3 styles */
  font-size: 14px;
}

.plot-root svg {
  overflow: visible;
}
