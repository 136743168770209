@value (
  --box-shadow-surface-2,
  --color-foreground,
  --space-1x,
  --space-2x
) from "~variables.css";

.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-root {
  max-width: 700px;
  margin: auto;
  position: relative;
}

.plot-details {
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: right;
}

.tooltip {
  background-color: --color-foreground;
  box-shadow: --box-shadow-surface-2;
  padding: --space-1x --space-2x;
}

.tooltip-value {
  white-space: nowrap;
}

.tooltip-value:not(:last-child) {
  margin-bottom: --space-1x;
}
