.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.icon {
  height: 30px;
  margin-bottom: 10px;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.dots {
  display: inline-block;
  width: 0;
}
