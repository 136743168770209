.root {
  height: 100%;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-root {
  position: relative;
  display: block;
  margin: auto;
  width: 500px;
}

.tooltip-root {
  position: absolute;
  top: 0;
  left: 0;
}
