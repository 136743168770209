.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scatter-plot-root {
  max-width: 1000px;
  margin: auto;
}

.explained-variance-chart-root {
  max-width: 600px;
  margin: auto;
}
