/* stylelint-disable font-family-no-missing-generic-family-keyword */

@import "~react-select/dist/react-select.css";
@import "~react-virtualized/styles.css";
@import "~react-virtualized-select/styles.css";
@import "~parcoord-es/dist/parcoords.css";

@value (
  --color-primary,
  --color-primary-darker,
  --color-text,
  --font-family-base
) from "~variables.css";

body {
  margin: 0;
  padding: 0;
  font-family: --font-family-base;
  color: --color-text;
}

a {
  color: --color-primary;
}

a:hover,
a:focus {
  color: --color-primary-darker;
}

/* Overriding global styles imported from react-select */
.Select-control {
  border-color: #a6a6a6;
  border-radius: 4px;
}
