.root {
  height: 100%;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
