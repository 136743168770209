@value (
  --color-danger,
  --color-foreground,
  --color-gray-lightest,
  --color-primary,
  --font-size-smaller,
  --font-weight-regular,
  --space-1x,
  --space-2x,
  --size-8x
) from "~variables.css";
@value (
  --main-padding-horizontal,
  --main-padding-horizontal-negative
) from "~containers/Layout/AuthenticatedLayout/style.css";
@value --table-border: 1px solid --color-gray-lightest;

.root { /* placeholder class */ }

.full-width-table-wrapper {
  margin: 0 --main-padding-horizontal-negative;
  overflow-x: scroll;
  will-change: transform; /* Reduce scrolling jank on wide table */
}

.responsive-table-wrapper {
  padding: 0 --main-padding-horizontal;
  min-width: 100%;

  /**
   * inline-block makes the browser respect the right padding when this element overflows its parent
   * and is scrolled to the far right. Unfortunately, this generates additional bottom margin for
   * text descenders, so subsequent elements are pushed down. We deal with it in .pager class.
   **/
  display: inline-block;
}

.table {
  border: --table-border;
  width: 100%;
}

.table thead {
  border: --table-border;
  background: --color-gray-lightest;
}

.table tbody .table-row {
  background: --color-foreground;
  border: --table-border;
}

.table-column {
  padding: --space-1x --space-2x;
  font-size: --font-size-smaller;
  font-weight: --font-weight-regular;
}

.table-column--narrow {
  width: 1px; /* Forces the column to shirnk down to its contents. */
}

.table-column--wide {
  min-width: 150px;
}

.table-cell,
.table-column {
  position: relative;
}

.table-cell {
  padding: --space-1x --space-2x;
  font-size: --font-size-smaller;
  font-weight: --font-weight-regular;
}

.table-cell--missing,
.table-cell--unknown {
  opacity: 0.5;
  font-style: italic;
}

.table-cell--error {
  border: 1px double --color-danger;
  color: --color-danger;
  background: --color-foreground;
}

.nowrap {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: --font-size-smaller;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: --color-gray-lightest;
  margin: 0 --main-padding-horizontal-negative;
  padding: --space-1x calc(--main-padding-horizontal + --space-2x); /* stylelint-disable-line function-calc-no-unspaced-operator */
  position: sticky;
  bottom: 0;

  /**
   * Push the pager up so it sticks to the table
   **/
  margin-top: -5px;
}

.pager-summary {
  font-size: --font-size-smaller;
  flex: 1 0 0;
}

.pager-more {
  border: 0;
  background: transparent;
  color: --color-primary;
}

.tools-section {
  height: --size-8x;
  display: flex;
  align-items: center;
}
