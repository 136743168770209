@value --color-primary, --icon-tint-filter from "~variables.css";
@value --tab-separator-color: #dedede;

.root { /* placeholder class */ }

.divider {
  margin: 0 -10px 0 -10px;
}

.tabs {
  display: flex;
  margin: 20px 0;
}

.tab {
  font-weight: 400;
  font-size: 12px;
  padding: 0 17px 0 13px;
  border-left: 1px solid --tab-separator-color;
  display: flex;
  align-items: center;
  color: inherit;
}

.tab:hover,
.tab:focus {
  text-decoration: none;
}

.tab > img {
  margin: 0;
  margin-right: 10px;
}

.active-tab > img {
  filter: --icon-tint-filter;
}

.tab:first-child {
  padding-left: 0;
  border-left: 0;
}

.active-tab {
  color: --color-primary;
  font-weight: 500;
}
