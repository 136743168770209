@value (
  --box-shadow-surface-3,
  --color-danger,
  --color-foreground,
  --color-gray-lighter,
  --color-text-lighter,
  --font-size-base,
  --font-size-smallest,
  --font-weight-medium,
  --size-3x,
  --space-1x,
  --space-2x
) from "~variables.css";

.root {
  position: relative;
  display: inline-block;
}

.criteria-root {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: --color-foreground;
  box-shadow: --box-shadow-surface-3;
  padding: --space-2x --space-2x 0;
  z-index: 2;
}

.criteria {
  margin-bottom: --space-2x;
}

.criteria-empty {
  text-align: center;
}

.criterion {
  display: flex;
  align-items: flex-start;
}

/* Select dropdowns */
.criterion-select :global(.Select-control) {
  border: 0;
}

.criterion-select :global(.Select-value) {
  /* position: relative makes the select width expand if the selected value is long */
  position: relative !important;
}

.criterion-select :global(.Select-input) {
  position: absolute;
  top: 0;
}

.criterion-select :global(.Select-value-label) {
  font-weight: --font-weight-medium;
  font-size: --font-size-base;
}

.criterion-select :global(.Select-arrow) {
  width: --size-3x;
  height: --size-3x;
  background: url("~icons/icon-chevron-down-black.svg");
  margin-top: 3px;

  /* Overriding react-select */
  border: 0 !important;
  top: 0 !important;
}

.criterion-select :global(.Select-menu-outer) {
  /* Expand the select menu to full width with long select options. */
  width: auto;
  min-width: 100%;
}

.criterion-select :global(.Select-control):hover {
  box-shadow: none;
}

.criterion-select:global(.is-open .Select-arrow) {
  background: url("~icons/icon-chevron-up-black.svg");
}

.criterion-scope,
.criterion-matcher {
  composes: criterion-select;
  min-width: 200px;
}

.criterion-query {
  height: 36px;
  min-width: 180px;
  padding: 0 --space-1x;
  border: 1px solid --color-gray-lighter;
  border-radius: 4px;
}

.criterion-query--invalid {
  border-color: --color-danger;
  border-width: 2px;
  outline-color: --color-danger;
}

.criterion-query--textarea {
  composes: criterion-query;
  height: initial;
}

.criterion-query--textarea-hint {
  font-size: --font-size-smallest;
  color: --color-text-lighter;
  text-align: right;
}

.criterion-remove-button {
  background: transparent;
  border: 0;
  margin-top: 5px;
}

.criteria-buttons {
  margin: 0 calc(-1 * --space-2x); /* stylelint-disable-line function-calc-no-unspaced-operator */
}

.criteria-button {
  padding: 0 calc(--space-2x + 10px); /* stylelint-disable-line function-calc-no-unspaced-operator */
}
