@value (
  --color-text-lighter,
  --font-size-smaller,
  --font-size-smallest,
  --font-weight-medium,
  --space-1x,
  --space-2x
) from "~variables.css";

.root { /* placeholder class */ }

.list {
  width: 100%;
}

.event {
  margin-bottom: --space-2x;
}

.event-content {
  font-size: --font-size-smaller;
  font-weight: --font-weight-medium;
  word-break: break-all;
  cursor: pointer;
}

.event-timestamp {
  font-size: --font-size-smallest;
  color: --color-text-lighter;
  margin-bottom: --space-1x;
}

.event-timestamp abbr {
  border-bottom: 0; /* Override bootstrap */
}
