.root {
  height: 100%;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot-area-wrapper {
  width: 100%;
  display: flex;
}

.plot-area {
  /* Defined by importing parcoord-es/dist/parcoords.css */
  composes: parcoords from global;
  height: 300px;
  flex-grow: 1;
}

.plot-area :global(.label) {
  font-size: inherit;
  font-weight: 400;
}

.x-axis-label {
  width: 1em;
  display: flex;
  align-items: center;
}

.x-axis-label > span {
  transform: rotate(-90deg);
  white-space: nowrap;
}
