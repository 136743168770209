@value (
  --color-primary,
  --color-primary-darker,
  --color-foreground,
  --color-text,
  --border-surface-0,
  --box-shadow-surface-1,
  --font-size-base,
  --font-size-smaller,
  --space-1x,
  --space-2x,
  --space-3x,
  --space-4x
) from "~variables.css";

.root {
  margin-top: --space-1x;
}

.dropzone {
  width: 100%;
  min-height: calc(100vh - 200px);
  border: --border-surface-0;
  border-style: dashed;
  border-radius: 4px;
  background: --color-foreground;
  padding: --space-3x;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: box-shadow 300ms;
}

.dropzone--active {
  box-shadow: --box-shadow-surface-1;
}

.dropzone--filled {
  cursor: inherit;
  min-height: inherit;
  border-style: solid;
}

.dropzone-message {
  color: --color-text;
  font-size: --font-size-base;
  margin: --space-3x 0 --space-4x;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone-message--small {
  composes: dropzone-message;
  font-size: --font-size-smaller;
  margin: --space-2x 0 0;
}

.dropzone-examples {
  composes: dropzone-message;
  font-size: --font-size-smaller;
  margin: --space-2x 0 0;
  display: block;
  text-align: center;
}

.dropzone-examples-button {
  display: inline;
  border: 0;
  background: transparent;
  color: --color-primary;
  text-decoration: underline;
  padding: 0;
  margin: 0 0 0 --space-1x;
}

.dropzone-examples-button:hover,
.dropzone-examples-button:focus {
  color: --color-primary-darker;
}

.dropzone--active .dropzone-message {
  color: --color-primary;
}
