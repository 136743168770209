@value (
  --font-weight-regular,
  --font-size-base,
  --space-1x,
  --space-2x,
  --space-3x,
  --size-3x
) from "~variables.css";

.root {
  display: flex;
  border: 1px dashed rgba(210, 43, 43, 0.33);
  border-radius: 4px;
  padding: --space-2x;
}

.icon {
  flex-grow: 0;
  margin-right: --space-2x;
}

.inner {
  flex-grow: 1;
}

.title {
  min-height: --size-3x;
  line-height: --size-3x;
  display: flex;
  align-items: center;
  font-weight: --font-weight-regular;
  font-size: --font-size-base;
}

.inner--with-contents .title {
  margin-bottom: --space-1x;
}

.details {
  margin-top: 13px;
}

.details-term {
  /* Reset the color of terms rendered inside of the bootstrap's Alert */
  color: initial;
}

.message {
  white-space: pre-wrap;
}

.boundary-root {
  margin: 10px;
}

.form-root {
  margin-bottom: --space-3x;
}
