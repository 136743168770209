/* stylelint-disable font-family-no-missing-generic-family-keyword */
@value --marketing-font-family-headings from "~variables.css";
@value --breakpoint-medium from "~breakpoints.css";

.section {
  padding: 2.5em 0 5em;
  position: relative;
}

.section-header {
  font-size: 2em;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  font-family: --marketing-font-family-headings;
  font-weight: 700;
}

.section-header--inverted {
  composes: section-header;
  color: #fff;
}

.background-gradient {
  position: absolute;
  top: -1em;
  left: -150%;
  z-index: -1;
  height: 100%;
  width: 400%;
  background-image: linear-gradient(-180deg, #f4f4f4 0%, #fff 78%);
}

@media (--breakpoint-medium) {
  .section-header {
    font-size: 2.25em;
  }
}
