.form {
  width: 100%;
}

.form + * {
  margin-top: 26px;
}

.form label {
  display: flex;
}

.form input {
  margin-left: 10px;
  font-weight: normal;
  width: 100px;
  flex-grow: 1;
}

.form select {
  width: 100%;
  margin-bottom: 5px;
}
