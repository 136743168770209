@value (
  --box-shadow-surface-1,
  --color-primary-lighter,
  --color-foreground,
  --color-gray-lightest,
  --font-weight-medium,
  --font-size-base,
  --font-size-smallest,
  --size-3x,
  --size-5x,
  --space-1x,
  --space-2x,
  --space-5x
) from "~variables.css";

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0 calc(-1 * --space-1x); /* stylelint-disable-line function-calc-no-unspaced-operator */
}

.button {
  padding: --space-1x --space-1x --space-2x;
  background: transparent;
  border: 0;
  border-radius: 4px;
  appearance: none;
}

.button:hover,
.button:focus {
  background: --color-primary-lighter;
}

.button:disabled {
  opacity: 0.5;
}

.button:disabled:hover,
.button:disabled:focus {
  background: --color-gray-lightest;
}

.button-inner {
  display: flex;
}

.button-label {
  margin-top: 4px;
  font-weight: --font-weight-medium;
  font-size: --font-size-base;
  line-height: --size-3x;
}

.button-description {
  margin-top: 6px;
  font-weight: --font-weight-medium;
  font-size: --font-size-smallest;
  line-height: 1em;
  text-align: left;
}

.icon-outer {
  width: --size-5x;
  height: --size-5x;
  padding: 4px;
  margin-right: 12px;
}

.icon-inner {
  box-shadow: --box-shadow-surface-1;
  border-radius: 4px;
  background: --color-foreground;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.icon-inner > img {
  width: 100%;
  height: 100%;
}

.search-input-wrapper {
  position: relative;
}

.search-input-wrapper::before {
  content: '';
  position: absolute;
  top: --space-1x;
  left: --space-1x;
  display: block;
  width: --size-3x;
  height: --size-3x;
  background: url("~icons/icon-search-black.svg");
}

.search-input {
  composes: input from "~components/FormControls/style.css";
  width: 100%;
  padding-left: --space-5x;
}
